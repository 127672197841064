import * as express from "express";
import {
  validateToken,
  validateTokenAndEnsureSelfAccountIdMatches,
  validateUserIdentity
} from "../../internal-utils/server-auth";
import {
  AccountId,
  DeviceRegistration,
  NotificationType,
  OrgInvoiceTypes,
  RealTimeNotification,
  RealTimeNotification_Invoice,
  RealTimeNotification_Registration
} from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { getManagedPlayerBundlesForAccountIdFragment } from "../../query-fragments/playerBundle.fragments";
import { RTBDRefs } from "../../constants";
import { getSimpleNotificationRef } from "../../internal-utils/realtime-database-ref";
import moment from "moment";
import shortid from "shortid";
import { getInvoicesNeedingAttentionForPlayerBundleIds } from "../orgInvoice/helpers";

export async function account__server__syncRTDBNotificationsForAccountId(p: { accountId: AccountId }) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h, getAppPgPool, appOllieRtdb } = getServerHelpers();

  const managedPlayerBundles = (await h.PlayerBundle.query(getManagedPlayerBundlesForAccountIdFragment(p.accountId))).docs;
  const playerBundleIds = managedPlayerBundles.map(mpb => mpb.id);

  const query = `select v.*
from f_player_bundle_registration_status(null, $1) v
where v.status <> 'no-registration-needed' and v.status <> 'registered'`;

  const [r1, realtimeNotificationsData, invoicesThatNeedAttention] = await Promise.all([
    getAppPgPool().query(query, [playerBundleIds]),
    RTBDRefs.getAccountSimpleNotificationRef({ accountId: p.accountId }).get(),
    getInvoicesNeedingAttentionForPlayerBundleIds({ playerBundleIds })
  ]);

  const realtimeNotifications = Object.values(realtimeNotificationsData.val() ?? {}) as RealTimeNotification[];

  const registrationsThatNeedAttention = r1.rows.map(a => {
    return {
      playerBundleId: a["player_bundle_id"],
      orgSeasonId: a["org_season_id"],
      status: a["status"]
    };
  });

  const registrationsThatDontHaveRealtime = registrationsThatNeedAttention.filter(
    a =>
      !realtimeNotifications.find(
        n => n.t === NotificationType.registration && n.pbId === a.playerBundleId && n.osId === a.orgSeasonId
      )
  );

  const invoicesThatDontHaveRealtime = invoicesThatNeedAttention.filter(
    a => !realtimeNotifications.find(n => n.t === NotificationType.invoice && n.oiId === a.orgInvoiceId)
  );

  const duplicateRealtimeNotifications = realtimeNotifications.filter(
    (rt, index) =>
      !!realtimeNotifications.find(
        (rt2, index2) =>
          (rt.id !== rt2.id &&
            rt.t === NotificationType.invoice &&
            rt2.t === NotificationType.invoice &&
            rt.oiId === rt2.oiId &&
            index < index2) ||
          (rt.id !== rt2.id &&
            rt.t === NotificationType.registration &&
            rt2.t === NotificationType.registration &&
            rt.osId === rt2.osId &&
            rt.pbId === rt2.pbId &&
            index < index2)
      )
  );

  let realtimesToClear = [
    ...realtimeNotifications.filter(
      n =>
        (n.t === NotificationType.registration &&
          !registrationsThatNeedAttention.find(r => r.orgSeasonId === n.osId && r.playerBundleId === n.pbId)) ||
        (n.t === NotificationType.invoice && !invoicesThatNeedAttention.find(a => a.orgInvoiceId === n.oiId))
    ),
    ...duplicateRealtimeNotifications
  ];

  const now = Date.now();

  await Promise.all([
    registrationsThatDontHaveRealtime.map(async r => {
      const realtimeToAdd: RealTimeNotification_Registration = {
        d: now,
        osId: r.orgSeasonId,
        pbId: r.playerBundleId,
        t: NotificationType.registration,
        e: moment().add(1, "year").valueOf(),
        id: shortid()
      };
      return getSimpleNotificationRef({ accountId: p.accountId }).child(realtimeToAdd.id).set(realtimeToAdd);
    }),
    invoicesThatDontHaveRealtime.map(async oi => {
      const realtimeToAdd: RealTimeNotification_Invoice = {
        d: now,
        oiId: oi.orgInvoiceId,
        pbId: oi.playerBundleId,
        t: NotificationType.invoice,
        e: moment().add(1, "year").valueOf(),
        id: shortid()
      };
      return getSimpleNotificationRef({ accountId: p.accountId }).child(realtimeToAdd.id).set(realtimeToAdd);
    }),
    realtimesToClear.map(async n => {
      return getSimpleNotificationRef({ accountId: p.accountId }).child(n.id).remove();
    })
  ]);
  // SERVER_ONLY_TOGGLE
}
account__server__syncRTDBNotificationsForAccountId.auth = async (req: express.Request) => {
  await validateUserIdentity({ request: req, propertyToCheck: "accountId" });
};

// i18n certified - complete
