import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { AccountSecretPaymentMethodId, PaymentMethod } from "@ollie-sports/models";
import { olliePipe__server__sendOlliePipeEvent } from "../olliePipe";

export async function accountSecret__server__deletePaymentMethod(p: {
  selfAccountId: string;
  accountSecretPaymentMethodId: AccountSecretPaymentMethodId;
}) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const accountSecret = await h.AccountSecret.getDoc(p.selfAccountId);

  if (!accountSecret) {
    throw new Error("Can't find account");
  }

  await h.AccountSecret.update({
    id: p.selfAccountId,
    doc: {
      paymentMethodsById: {
        [p.accountSecretPaymentMethodId]: h._MagicDeleteValue
      }
    }
  });

  // SERVER_ONLY_TOGGLE
}

accountSecret__server__deletePaymentMethod.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
