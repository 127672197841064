import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import {
  PaymentMethod,
  PaymentMethodOnCreation,
  PaymentMethodType,
  PaymentMethod__BankAccount,
  PaymentMethod__Card
} from "@ollie-sports/models";
import { DistributiveOmit } from "../../utils";
import { ServerThisContext } from "@ollie-sports/react-bifrost";
import { isDeployedCode } from "../../utils/server-helpers";
import { encryptPIFI } from "../../utils/pifi-crypto-helpers";
import axios, { AxiosResponse } from "axios";
import { translate } from "@ollie-sports/i18n";
import { nmiSDK } from "../../utils/nmiSDK";
import { logUnexpectedPaymentError, possiblyConvertNMIResponseCodeToTranslatedPrettyString } from "../../utils/payment-helpers";

export async function accountSecret__server__addPaymentMethod(
  this: ServerThisContext,
  p: {
    selfAccountId: string;
    paymentMethod: PaymentMethodOnCreation;
    locale: string;
  }
): Promise<
  | {
      status: "success";
      paymentMethodId: string;
    }
  | { status: "error"; prettyErrorMsg: string }
> {
  // SERVER_ONLY_TOGGLE
  const proto = this.request.headers["x-forwarded-proto"] || this.request.protocol;

  if (isDeployedCode() && proto !== "https") {
    throw new Error("May only add payment methods over a secure connection!");
  }

  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  const accountSecret = await h.AccountSecret.getDoc(p.selfAccountId);

  if (!accountSecret) {
    throw new Error("Can't find account");
  }

  let oldDefaultPaymentMethod: PaymentMethod | undefined;
  if (p.paymentMethod.isDefault) {
    oldDefaultPaymentMethod = Object.values(accountSecret.paymentMethodsById ?? {}).find(
      paymentMethod => paymentMethod.isDefault
    );
  }

  let prettyFailureReason = translate({
    defaultMessage: "There was a problem authorizing your payment method",
    serverLocale: p.locale
  });

  //Verify the card is good with NMI
  if (p.paymentMethod.type === "card") {
    try {
      var verifyResp = await nmiSDK.verifyCC({
        fullName: p.paymentMethod.ownerName,
        expMM: p.paymentMethod.expMM,
        expYYYY: p.paymentMethod.expYYYY,
        number: p.paymentMethod.cardNumber,
        cvv: p.paymentMethod.cvv,
        postalCode: p.paymentMethod.postalCode
      });
    } catch (e) {
      await logUnexpectedPaymentError({
        errorCode: "charge-card-mysterious-authorize-error",
        locationId: "234klj3245dsfddf39",
        info: e,
        olliePipe
      });

      return {
        status: "error",
        prettyErrorMsg: prettyFailureReason
      };
    }

    if (verifyResp.response !== 1) {
      // const responseCode = verifyResp?.responseCode;
      // const possiblePrettyError = responseCode
      //   ? possiblyConvertNMIResponseCodeToTranslatedPrettyString({
      //       locale: p.locale,
      //       responseCode
      //     })
      //   : undefined;
      // if (possiblePrettyError) {
      //   prettyFailureReason += ` (${possiblePrettyError})`;
      // }
      prettyFailureReason += ".";

      prettyFailureReason += ` ${translate({
        defaultMessage: "Please make sure all of the information is correct and try again.",
        serverLocale: p.locale
      })}`;

      await olliePipe.emitEvent({
        type: "error-adding-payment-method",
        payload: {
          accountId: p.selfAccountId,
          nmiInfo: verifyResp,
          prettyFailureReason
        }
      });

      return {
        status: "error",
        prettyErrorMsg: prettyFailureReason
      };
    }
  }

  const now = Date.now();

  const newId = Math.random().toString().slice(3) + Math.random().toString().slice(3);
  let newPaymentMethod: PaymentMethod;
  if (p.paymentMethod.type === PaymentMethodType.card) {
    const cardMethod: PaymentMethod__Card = {
      type: PaymentMethodType.card,
      status: "active",
      createdAtMS: now,
      updatedAtMS: now,
      id: newId,
      brand: p.paymentMethod.brand,
      ownerName: p.paymentMethod.ownerName,
      isDefault: p.paymentMethod.isDefault,
      postalCode: p.paymentMethod.postalCode,
      expMM: p.paymentMethod.expMM,
      expYYYY: p.paymentMethod.expYYYY,
      cardNumberLast4: p.paymentMethod.cardNumber.trim().slice(-4),
      encryptedCardNumber: await encryptPIFI(p.paymentMethod.cardNumber.trim())
    };
    newPaymentMethod = cardMethod;
  } else {
    const cardMethod: PaymentMethod__BankAccount = {
      type: PaymentMethodType.bank,
      createdAtMS: now,
      updatedAtMS: now,
      id: newId,
      bankAccountType: p.paymentMethod.bankAccountType,
      ownerName: p.paymentMethod.ownerName,
      isDefault: p.paymentMethod.isDefault,
      routingNumber: p.paymentMethod.routingNumber,
      accountNumberLast4: p.paymentMethod.accountNumber.trim().slice(-4),
      encryptedAccountNumber: await encryptPIFI(p.paymentMethod.accountNumber.trim())
    };
    newPaymentMethod = cardMethod;
  }

  const paymentMethodUpdates: Record<string, Partial<PaymentMethod>> = {
    [newId]: newPaymentMethod
  };

  if (oldDefaultPaymentMethod) {
    paymentMethodUpdates[oldDefaultPaymentMethod.id] = {
      isDefault: h._MagicDeleteValue
    };
  }

  await h.AccountSecret.update({
    id: p.selfAccountId,
    doc: {
      paymentMethodsById: paymentMethodUpdates
    }
  });

  return { status: "success", paymentMethodId: newId };
  // SERVER_ONLY_TOGGLE
}

accountSecret__server__addPaymentMethod.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
