import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import { PaymentMethod, AccountSecretPaymentMethodId } from "@ollie-sports/models";

export async function accountSecret__server__setPaymentMethodAsDefault(p: {
  selfAccountId: string;
  accountSecretPaymentMethodId: AccountSecretPaymentMethodId;
}) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const { serverConfig } = getServerHelpers();

  const accountSecret = await h.AccountSecret.getDoc(p.selfAccountId);

  if (!accountSecret) {
    throw new Error("Can't find account");
  }

  const paymentMethodToUpdate = accountSecret.paymentMethodsById?.[p.accountSecretPaymentMethodId];

  if (!paymentMethodToUpdate) {
    throw new Error("Can't find payment method to set as default");
  }

  if (paymentMethodToUpdate.isDefault) {
    return;
  }

  let oldDefaultPaymentMethod = Object.values(accountSecret.paymentMethodsById ?? {}).find(
    paymentMethod => paymentMethod.isDefault
  );

  const paymentMethodUpdates = {
    [paymentMethodToUpdate.id]: { isDefault: true }
  };

  if (oldDefaultPaymentMethod) {
    paymentMethodUpdates[oldDefaultPaymentMethod.id] = {
      isDefault: h._MagicDeleteValue
    };
  }

  await h.AccountSecret.update({
    id: p.selfAccountId,
    doc: {
      paymentMethodsById: paymentMethodUpdates
    }
  });

  // SERVER_ONLY_TOGGLE
}

accountSecret__server__setPaymentMethodAsDefault.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};
