import { getServerHelpers, getUniversalHelpers } from "../helpers";
import {
  Account,
  AccountPrivate,
  NotificationSettings,
  EventFilterSettings,
  AUTH_TYPES,
  EVENT_REMINDER_NOTIFICATION_TYPES,
  EventReminderSettings__Default,
  OrgTeamTag
} from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { validateToken } from "../internal-utils/server-auth";
import * as express from "express";
import { schedulePosthook } from "../internal-utils/schedulePosthook";
import { miscEmails__server__sendOnboardingInteraction } from "./miscEmails";
import { convertToBifrostMultiDocSubscription } from "../internal-utils/bifrostHelpers";
import { Optional, SimpleQuery } from "@ollie-sports/firebase-lift";
import { firestoreLiftDocSubToBifrostSub } from "../internal-utils/firestoreLiftSubToBifrostSub";
import { ObjectKeys } from "../utils";
import _ from "lodash";
import { getBatchTasksToUpdateDerivedForAccount } from "../compute/account.compute";

export async function account__server__PasswordResetEmail(p: {
  email: string;
}): Promise<{ status: "success" } | { status: "no_account_with_email_found" } | { status: "retry" }> {
  // SERVER_ONLY_TOGGLE
  // Trim email to prevent errors w/ google and Sendgrid
  p.email = p.email.trim();
  const { emailTemplateServiceFn, appFirebaseAdminApp: admin } = getServerHelpers();
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let a = await h.Account.query({ where: [{ deletedAtMS: ["==", 0] }, { email: ["==", p.email] }] });

  let firstName = "Ollie User";
  if (a.docs.length === 1 && a.docs[0].firstName) {
    firstName = a.docs[0].firstName;
  }

  let resetLink = "";
  try {
    resetLink = await admin.auth().generatePasswordResetLink(p.email);
  } catch (e) {
    console.error(e);
    return { status: "retry" };
  }

  try {
    const statusCode = await emailTemplateServiceFn({
      to: p.email,
      templateId: "d-2e006a06d2f843b691bad32d0fe35798",
      templateData: {
        name: firstName,
        resetUrl: resetLink
      }
    });
    if (statusCode > 299) {
      return { status: "retry" };
    }
  } catch (e) {
    console.error(e);
    return { status: "retry" };
  }

  return { status: "success" };
  // SERVER_ONLY_TOGGLE
}

account__server__PasswordResetEmail.auth = () => {
  // Anyone can request a password reset
};

export async function account__client__getAccount(p: { accountId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  if (!p.accountId) {
    return null;
  }
  let res = await h.Account.getDoc(p.accountId);
  return res;
}

export async function account__server__getAccount(p: { accountId: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let res = await h.Account.getDoc(p.accountId);
  return res;
  // SERVER_ONLY_TOGGLE
}

account__server__getAccount.auth = async (r: express.Request) => {
  await validateToken(r);
};

export function account__client__accountSubscription(p: { accountId: string }): BifrostSubscription<Account | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.Account.docSubscription(p.accountId));
}

export function account__client__accountsSubscription(p: { accountIds: string[] }): BifrostSubscription<Array<Account | null>> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let subRef = h.Account.docsSubscription(p.accountIds);
  return convertToBifrostMultiDocSubscription({ subRef });
}

export async function account__server__updatePublic(p: { accoundId: string; newAccount: Account }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let oldAccount = await h.Account.getDoc(p.accoundId);
  if (!oldAccount) {
    throw new Error("Account not found to update");
  }
  if (oldAccount.id !== p.newAccount.id) {
    throw new Error("Cannot change ID of an account");
  }
  if (p.newAccount.profileImageUri === "") {
    p.newAccount.profileImageUri = h._MagicDeleteValue;
    p.newAccount.profileImageUriSmall = h._MagicDeleteValue;
  }
  await h.Account.update({ id: p.accoundId, doc: p.newAccount });
  await h._BatchRunner.executeBatch(await getBatchTasksToUpdateDerivedForAccount({ accountId: p.accoundId }));

  // SERVER_ONLY_TOGGLE
}
account__server__updatePublic.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

export async function account__client__getAccountPrivate(p: { accountId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let res = await h.AccountPrivate.getDoc(p.accountId);
  if (!res) {
    console.error("Unable to find account");
  }

  return res;
}

export function account__client__accountPrivateSubscription(p: {
  accountId: string;
}): BifrostSubscription<AccountPrivate | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.AccountPrivate.docSubscription(p.accountId));
}

export async function account__client__updatePrivate(p: { accountId: string; update: Optional<AccountPrivate> }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.update({ id: p.accountId, doc: p.update });
}

export async function account__client__updatePrivateEventFilterSettings(p: {
  accountId: string;
  filterSettings: EventFilterSettings;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.AccountPrivate.setPath({
    id: p.accountId,
    pathObj: { settings: { eventFilters: true } },
    value: {
      settings: { eventFilters: p.filterSettings }
    }
  });
}
account__client__updatePrivateEventFilterSettings.auth = async (r: express.Request) => {
  await validateToken(r);
  // Make sure valid auth token
  // Make sure user has auth to update this
};

export async function account__client__setNotificationPreference(p: {
  accountId: string;
  notifications: Required<AccountPrivate>["settings"]["notifications"];
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.AccountPrivate.update({ id: p.accountId, doc: { settings: { notifications: p.notifications } } });
}

export async function account__server__signup(
  p: Omit<Account, "id" | "deletedAtMS"> & {
    password: string;
    communicationLocale: string;
  }
): Promise<{ success: false; errorMessage: string; errorCode: string } | { success: true; accountId: string }> {
  // SERVER_ONLY_TOGGLE
  const { appFirebaseAdminApp, serverConfig } = getServerHelpers();
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const { password, communicationLocale, ...account } = p;

  //Force email to be lowercase
  account.email = account.email.toLowerCase();

  try {
    var authAccount = await appFirebaseAdminApp.auth().createUser({ email: account.email, password });
    var uid = authAccount.uid;
  } catch (e) {
    if (e instanceof Error) {
      const err: any = e;
      if (err.code && err.message) {
        return { success: false, errorMessage: err.message, errorCode: err.code };
      }
    }
    throw e;
  }

  const now = Date.now();

  try {
    h._BatchRunner.executeBatch([
      await h.Account.add({ doc: { id: uid, deletedAtMS: 0, ...account } }, { returnBatchTask: true }),
      await h.AccountPrivate.add(
        {
          doc: {
            id: uid,
            createdAtMS: now,
            communicationLocale,
            authType: AUTH_TYPES.password,
            settings: {
              conversations: {},
              notifications: {
                calendarEvents: true,
                awards: true,
                statsAndGame: true,
                teamAndOrgChannels: true
              },
              eventReminderSettings: EventReminderSettings__Default
            },
            agreements: {
              age: {
                date: now
              },
              terms: {
                date: now
              }
            }
          }
        },
        { returnBatchTask: true }
      ),
      await h.AccountSecret.add({ doc: { id: uid, createdAtMS: Date.now() } }, { returnBatchTask: true })
    ]);
  } catch (e) {
    olliePipe.emitEvent({ type: "error-on-signup", payload: { email: p.email } });
    try {
      await Promise.all([
        appFirebaseAdminApp.auth().deleteUser(uid),
        h.Account.delete({ id: uid }),
        h.AccountPrivate.delete({ id: uid }),
        h.AccountSecret.delete({ id: uid })
      ]);
      console.info("Recovered from failed signup");
    } catch (er) {
      await olliePipe.emitEvent(
        { type: "error-unable-to-cleanup-on-bad-account-signup", payload: { uid, email: p.email, error: er } },
        { sendImmediate: true }
      );
      //Not sure how to recover...
    }

    throw e;
  }

  try {
    await Promise.all([
      schedulePosthook({
        webhookData: {
          data: { accountId: uid, sequenceNumber: 1 }
        },
        bifrostFn: miscEmails__server__sendOnboardingInteraction,
        minutes: 0, // immediate
        apiKey: serverConfig.posthook.apiKey
      }),
      schedulePosthook({
        webhookData: {
          data: { accountId: uid, sequenceNumber: 2 }
        },
        bifrostFn: miscEmails__server__sendOnboardingInteraction,
        minutes: 48 * 60, // 2 days
        apiKey: serverConfig.posthook.apiKey
      }),
      schedulePosthook({
        webhookData: {
          data: { accountId: uid, sequenceNumber: 3 }
        },
        bifrostFn: miscEmails__server__sendOnboardingInteraction,
        minutes: 168 * 60, // 1 week
        apiKey: serverConfig.posthook.apiKey
      }),
      schedulePosthook({
        webhookData: {
          data: { accountId: uid, sequenceNumber: 4 }
        },
        bifrostFn: miscEmails__server__sendOnboardingInteraction,
        minutes: 336 * 60, // 2 weeks
        apiKey: serverConfig.posthook.apiKey
      })
    ]);
  } catch (e) {
    await olliePipe.emitEvent({ payload: e, type: "error-unable-to-schedule-welcome-emails" }, { sendImmediate: true });
  }

  return { success: true, accountId: uid };
  // SERVER_ONLY_TOGGLE
}
account__server__signup.auth = () => {};

// Occasionally we have hit an issue where a firebase account is created but all of the associated firestore documents were not
// or vice versa. After spending some time on it we still have no idea how this occurs. Seems like it shouldn't be possible
// For now we are going to run this the first time they get a firebase token and on login
// It will check fo the existence of the other docments and clear things up if needed
export async function account__server__accountExistsCheck(p: {
  email: string;
}): Promise<{ status: "reset" | "good" | "contact-support" | "in-use" }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const { appFirebaseAdminApp } = getServerHelpers();

  const [authUser, accountDoc] = await Promise.all([
    appFirebaseAdminApp
      .auth()
      .getUserByEmail(p.email)
      .catch(() => null),
    h.Account.query({ where: [{ email: ["==", p.email.toLowerCase()] }] }).then(a => a.docs.shift())
  ]);

  if (!authUser && accountDoc) {
    const accountId: string = accountDoc.id;
    const userTeams = await h.Team.query({ where: [{ accounts: { [accountId]: { exists: ["==", true] } } }] });
    if (!userTeams.docs.length) {
      console.info("RESETTING ACCOUNT");
      olliePipe.emitEvent({
        type: "error-auto-reset-account-bad-firebase",
        payload: { accountDoc, authUser }
      });
      await h.Account.delete({ id: accountDoc.id });
      await h.AccountPrivate.delete({ id: accountDoc.id });
      return { status: "reset" };
    } else {
      olliePipe.emitEvent({
        type: "error-corrupted-auth-account-state-no-firebase-user",
        payload: { accountDoc, authUser, userTeams }
      });
      return { status: "contact-support" };
    }
  } else if (!accountDoc && authUser) {
    const userTeams = await h.Team.query({ where: [{ accounts: { [authUser.uid]: { exists: ["==", true] } } }] });
    if (!userTeams.docs.length) {
      olliePipe.emitEvent({
        type: "error-auto-reset-account-bad-account",
        payload: { accountDoc, authUser, userTeams }
      });
      console.info("RESETTING ACCOUNT");
      await appFirebaseAdminApp.auth().deleteUser(authUser.uid);
      return { status: "reset" };
    } else {
      olliePipe.emitEvent({
        type: "error-corrupted-auth-account-state-no-account",
        payload: { authUser }
      });
      return { status: "contact-support" };
    }
  } else {
    if (!!authUser && !!accountDoc) {
      return { status: "in-use" };
    }
    return { status: "good" };
  }
  // SERVER_ONLY_TOGGLE
}

account__server__accountExistsCheck.auth = async (e: any) => {};

// i18n certified - complete
